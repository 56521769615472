<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg3>
            <v-card class="elevation-12">
              <v-toolbar
                dark
                prominent
                src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
              >
                <v-toolbar-title>Gowawe Awakens</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon>mdi-export</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    v-model="details.email"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="details.password"
                    label="Password"
                    type="password"
                    v-on:keyup.enter="login2"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  dark
                  block
                  outlined
                  large
                  @click="login2"
                  color="primary"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-dialog v-model="dialog.statu" persistent width="300">
        <v-card :color="dialog.color" dark>
          <v-card-text class="p-3">
            {{ dialog.text }}
            <v-progress-linear
              v-if="dialog.loading"
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialogReset">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",

  data: function() {
    return {
      dialog: {
        statu: false,
        color: "primary",
        text: "Loading, please wait..",
        loading: true,
      },
      details: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),

    dialogReset: function() {
      this.dialog = {
        statu: false,
        color: "primary",
        text: "Loading, please wait..",
        loading: true,
      };
    },
    login2: function() {
      let th = this;

      if (
        this.details.email.trim() == "" ||
        this.details.password.trim() == ""
      ) {
        return false;
      }

      this.dialog.statu = true;

      this.sendLoginRequest(this.details).then((res) => {
        if (res && res.data.success && res.data.user) {
          window.location.reload();
        } else {
          this.dialog.text = "Incorrect User Login.";
          this.dialog.color = "red";
          this.dialog.loading = false;
        }
      });
    },
  },
};
</script>
